import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {useWidgetState} from '../../hooks/state-provider'
import {useEventsList} from '../../hooks/use-events-list'
import {getCompId} from '../../selectors/component'
import {focusFirstNewEvent} from '../../utils/a11y'
import {LoadMoreButton} from '../load-more-button'
import {MainListTitle} from '../main-list-title'
import {SideBySideProps} from './interfaces'
import OldSideBySideItem from './side-by-side-item/old'
import {SideBySideItem} from './side-by-side-item/side-by-side-item'
import s from './side-by-side.scss'
import {Utils} from './utils'

export const SideBySide = ({t, width}: SideBySideProps) => {
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const {experiments} = useExperiments()

  const utils = new Utils()
  const compId = useWidgetState(getCompId)
  const eventsCount = events.length
  const loadMoreVisible = hasMore || moreLoading
  const widgetSpacingUpdatesEnabled = experiments.enabled(ExperimentNames.WidgetSpacingUpdates)

  useEffect(() => {
    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId})
  }, [eventsCount])

  return (
    <div className={s.container}>
      <MainListTitle />
      <div className={s.items}>
        <ul data-hook={DH.sideBySideItems} className={s.list}>
          {events.map((event: ExtendedEvent, index: number) => (
            <li
              key={event.id}
              className={classNames(s.item, utils.itemsHeightClass(width))}
              data-hook={DH.sideBySideItem}
            >
              {widgetSpacingUpdatesEnabled ? (
                <SideBySideItem event={event} evenItem={!!(index % 2)} />
              ) : (
                <OldSideBySideItem event={event} evenItem={!!(index % 2)} t={t} />
              )}
            </li>
          ))}
        </ul>
        {loadMoreVisible && (
          <div className={s.loadMoreWrapper}>
            <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
          </div>
        )}
      </div>
    </div>
  )
}
