import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useRef, useState} from 'react'
import {isEventsInMembersInstalled} from '../../../../../../commons/selectors/installed-apps'
import {useWidgetState} from '../../../hooks/state-provider'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {shouldShowCountdown} from '../../../selectors/layout'
import {
  getComponentConfig,
  getSideBySideItemLeftPadding,
  getSideBySideItemRightPadding,
  isMembersVisible,
  isRibbonVisible,
} from '../../../selectors/settings'
import classesV2 from '../../classes-v2.scss'
import {Countdown} from '../../countdown'
import {EventImage} from '../../event-image'
import {FullDateLocation} from '../../full-date-location'
import {LinkToPage} from '../../link-to-page'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import s from './side-by-side-item.scss'

const initialDetailsStyles = {
  scrollContainer: {},
  content: {},
}

interface SideBySideItemProps {
  event: ExtendedEvent
  evenItem: boolean
}

export const SideBySideItem = ({event, evenItem}: SideBySideItemProps) => {
  const {t} = useTranslation()
  const {isRTL} = useEnvironment()

  const componentConfig = useWidgetState(getComponentConfig)
  const showMembers = useWidgetState(state => isMembersVisible(state, event))
  const membersEnabled = useWidgetState(isEventsInMembersInstalled)
  const showRibbon = useWidgetState(state => isRibbonVisible(state, event))
  const showCountdown = shouldShowCountdown(event)
  const sideBySideItemLeftPadding = getSideBySideItemLeftPadding(componentConfig)
  const sideBySideItemRightPadding = getSideBySideItemRightPadding(componentConfig)

  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const [scrollableContainerStyles, setScrollableContainerStyles] = useState(initialDetailsStyles)

  const {
    allBreakpoints: {
      isListCountdownEnabled,
      isListShortDateLocationVisible_V2,
      isListFullDateLocationVisible_V2,
      isListDescriptionEnabled,
    },
  } = useVisibilityStyles()

  const handleMouseOver = () => {
    const scrollableContainerWidth = scrollableContainerRef.current.getBoundingClientRect().width

    setScrollableContainerStyles({
      scrollContainer: {
        overflow: 'auto',
      },
      content: {
        width: scrollableContainerWidth - (isRTL ? sideBySideItemLeftPadding : sideBySideItemRightPadding),
        padding: 0,
        paddingInlineStart: isRTL ? sideBySideItemRightPadding : sideBySideItemLeftPadding,
      },
    })
  }

  const handleMouseOut = () => setScrollableContainerStyles(initialDetailsStyles)

  return (
    <div
      className={classNames(s.container, evenItem ? s.even : s.odd)}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={s.imageContainer}>
        <EventImage event={event} opacityFallback backgroundFallback className={s.image} />
      </div>
      <div className={s.content}>
        <div className={s.details}>
          {showRibbon && (
            <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />
          )}
          {isListCountdownEnabled() && showCountdown && (
            <div className={s.countdown}>
              <Countdown event={event} />
            </div>
          )}
          <div
            className={s.scrollContainer}
            ref={scrollableContainerRef}
            style={scrollableContainerStyles.scrollContainer}
          >
            <div className={s.scrollContent} style={scrollableContainerStyles.content}>
              <div className={s.title}>
                <LinkToPage allowExternal event={event} dataHook="title">
                  {event.title}
                </LinkToPage>
              </div>
              {isListShortDateLocationVisible_V2() && (
                <div className={s.shortDateLocation}>
                  <ShortDateLocation
                    event={event}
                    dateClassName={classesV2.eventDate}
                    locationClassName={classesV2.eventLocation}
                  />
                </div>
              )}
              {isListFullDateLocationVisible_V2() && (
                <div className={s.fullDateLocation}>
                  <FullDateLocation
                    event={event}
                    dateClassName={classesV2.eventDate}
                    locationClassName={classesV2.eventLocation}
                  />
                </div>
              )}
              {!!event.description && isListDescriptionEnabled() && (
                <div className={s.description}>{event.description}</div>
              )}
              {showMembers && (
                <div className={s.members}>
                  <Members event={event} />
                </div>
              )}
              {membersEnabled && <SocialBar event={event} t={t} className={s.socialBar} />}
            </div>
          </div>
        </div>
        <div className={s.rsvpButton}>
          <RsvpButton event={event} />
        </div>
      </div>
    </div>
  )
}
